<div class="contact-container">



<div class="row mb-4">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6">
        <h2>Contact Us</h2>
    </div>
    <div class="col-12 col-md-3"></div>
</div>

<div class="row">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6">
        <br>
    <p>Feel free to reach out via email to <a href="mailto:adin@aren-consulting.com?subject=Contact%20from%20Website">adin@aren-consulting.com</a></p>
    <a href="mailto:example@example.com?subject=Contact%20from%20Website">
        Send us an email
    </a>   
    </div>
    <div class="col-12 col-md-3"></div>
</div>








    
     
  </div>
  