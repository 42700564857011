
<div class="row">
<div class="col-8">
    <!-- <h5 >{{'CPV-Helper' | translate }}</h5> -->
    <!-- <p>{{'Categorise public procurrements into relevant CPV codes using title and description' | translate }}</p> -->
    <p>{{'Input title and description of a public procurrement and get an automatic CPV classification suggestion' | translate
      }}</p>
  </div>
  <div class="col-4" style="margin-top: 60px;">

    <!-- comment out below code for select -->
    <!-- <select style="background-color: #F4F7F7; width: auto; border: none;" class="selectpicker show-menu-arrow form-control float-right" aria-label=".form-select-lg example" [(ngModel)]="selectedLanguage" (ngModelChange)="changeLanguage($event)">
          <option style="background-color: #F4F7F7;" *ngFor="let language of languages" [ngValue]="language.value">
            {{language.viewValue }}
          </option>
      </select> -->

  </div>
</div>
    
    
    <div class="row">
        <div class="col-12">
            <div class="row">

                <!-- <mat-form-field>
                <input formControlName="title" matInput />
              </mat-form-field> -->


                <div class="col-md-5">
                    <div class="row">
                        <div class="col-12">

                        </div>
                    </div>
                    
                    <form [formGroup]="procurementForm" (ngSubmit)="saveFormData(procurementForm.valid, $event)">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field style="width: 100%;" appearance="fill" >
                                    <mat-label>{{'Title' | translate}}</mat-label>
                                    <input formControlName="title" matInput required />
                                </mat-form-field>
                                <mat-form-field style="width: 100%;" appearance="fill" >
                                    <mat-label>{{'Description' | translate}}</mat-label>
                                    <textarea class="md-textarea" formControlName="description" matInput rows="12"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                       
                        <div class="row">
                            <div class="col-12">
                                <button *ngIf="!submitting" mat-raised-button>{{'Send' | translate}}</button>
                        <div *ngIf="submitting" class="spinner">
                            <mat-spinner [diameter]="30" [strokeWidth]="6"></mat-spinner>
                        </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
                <!-- <div class="col-1">

                </div> -->
                <div class="col-md-7">
                    <!-- <button mat-raised-button (click)="addRow()">Add row</button> -->
                    <mat-table class="table" #table [dataSource]="dataSource">

                        <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->

                       

                        <!-- Code Column -->
                        <ng-container matColumnDef="code">
                            <mat-header-cell style="color: #212529;" *matHeaderCellDef>{{'Code' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.code}} </mat-cell>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell style="color: #212529;" *matHeaderCellDef>{{'Name' | translate}}</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                        </ng-container>

                         <!-- Confidence Column -->
                         <!-- <ng-container matColumnDef="confidence">
                            <mat-header-cell *matHeaderCellDef>Confidence</mat-header-cell>
                            <mat-cell *matCellDef="let row"> {{row.confidence}} </mat-cell>
                        </ng-container> -->

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row [@rowsAnimation]="" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </div>


        </div>
    </div>
