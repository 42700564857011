<div class="row mb-4">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6">
        <h2>Cookies</h2>
    </div>
    <div class="col-12 col-md-3"></div>
</div>

<div class="row">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 col-md-6">
        <p>We use cookies to enhance your experience on our website. Cookies are small text files stored on your device
            when you visit a website. They help us remember your preferences, understand how you use our site, and
            provide you with relevant content and advertising.</p>

        <h3>Types of Cookies We Use</h3>
        <ul>
            <li><strong>Necessary Cookies:</strong> These cookies are essential for the website to function properly.
                They enable basic functions like page navigation and access to secure areas of the website.</li>
            <li><strong>Preference Cookies:</strong> These cookies allow our website to remember choices you have made
                in the past, like your preferred language or the region you are in.</li>
            <li><strong>Analytics Cookies:</strong> These cookies help us understand how visitors interact with our
                website by collecting and reporting information anonymously.</li>
            <li><strong>Advertising Cookies:</strong> These cookies are used to track visitors across websites to
                display ads that are relevant and engaging for the individual user.</li>
        </ul>

        <h3>Managing Cookies</h3>
        <p>You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse
            cookies or alert you when a cookie is being sent. Please note that some parts of our website may not
            function properly if you disable cookies.</p>

        <h3>Third-Party Cookies</h3>
        <p>We may also use third-party cookies, such as Google Analytics, to collect information about your interactions
            with our website. These cookies are managed by the third parties and are subject to their privacy policies.
        </p>

    </div>
    <div class="col-12 col-md-3"></div>
</div>