<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <title>CPV-Helper</title>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
  <style>
    body {
      background-color: #f8f9fa;
    }

    .navbar-brand {
      font-weight: bold;
    }

    .navbar-nav .nav-link {
      font-weight: bold;
    }
  </style>
</head>

<body>

  <nav class="navbar navbar-expand-lg navbar-light bg-body-tertiary">
    <div class="container">
      <a class="navbar-brand" href="#">CPV-Helper</a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" aria-controls="navbarNav"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{'show': !isCollapsed}" id="navbarNav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/about" routerLinkActive="active">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled">CPV tree</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled">CPV code search</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container" style="margin-top: 30px;">
    <div class="row">
      <div class="col-12">
        <router-outlet></router-outlet>

      </div>
    </div>
  </div>

  <!-- app.component.html -->
  <div id="consent-banner">
    <p>We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.
      Learn more about our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>
    <div>
      <label><input type="checkbox" id="ad_user_data" checked> Ad User Data</label>
      <label><input type="checkbox" id="ad_personalization" checked> Ad Personalization</label>
      <label><input type="checkbox" id="ad_storage" checked> Ad Storage</label>
      <label><input type="checkbox" id="analytics_storage" checked> Analytics Storage</label>
    </div>
    <button (click)="grantConsent()" id="grantButton">Save Preferences</button>
  </div>

  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
</body>

</html>