import { NgModule, DoBootstrap } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ClassifierFormComponent } from './components/classifier-form/classifier-form.component';


import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [AppComponent, ClassifierFormComponent, ContactComponent, AboutComponent, PrivacypolicyComponent],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    AppRoutingModule,
  ],
  exports: [

  ]
})

export class AppModule {
  constructor(public translate: TranslateService) {
    const defaultLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    translate.addLangs(['sv', 'en']);
    translate.setDefaultLang(defaultLanguage);
    translate.use(defaultLanguage);
  }
}
