import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { rowsAnimation } from '../../animations/table-animations';
import { MatTableDataSource } from '@angular/material/table'
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import codeToSV from 'assets/cpv-to-string/SV.json'
import codeToEN from 'assets/cpv-to-string/EN.json'
const codeToLang = {
  'sv' : codeToSV,
  'en'  : codeToEN
}
interface CpvResult {
  code: string;
  name: string;
  //confidence: string;
}


@Component({
  selector: 'xxx-classifier-form',
  templateUrl: './classifier-form.component.html',
  styleUrls: ['./classifier-form.component.scss'],
  animations: [rowsAnimation]
})
export class ClassifierFormComponent implements OnInit {

  //displayedColumns = [ 'code', 'name', 'confidence'];
  displayedColumns = [ 'code', 'name'];
  dataSource: MatTableDataSource<CpvResult>;
  procurementForm: FormGroup
  submitting: boolean = false
  finishedSubmitting: boolean = true
  subscriptions: Subscription[] = []
  constructor(private fBuilder: FormBuilder, private http: HttpClient, private translate: TranslateService) {

    const CPVCodes: CpvResult[] = [];
    this.dataSource = new MatTableDataSource(CPVCodes);
  }

  ngOnInit() {
    this.createNewForm()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  //createNewCPVRow(code: string, confidence: number): CpvResult {
    createNewCPVRow(code: string): CpvResult {
    let name : any
    if (this.translate.currentLang == 'fr'){
      name = codeToLang['en'][code]
    } else {
      name = codeToLang[this.translate.currentLang][code]
    }
    
    return {
      //'confidence': Number(confidence).toFixed(7).toString(),
      'code': code,
      'name': name
    };
  }

  // Adds new user.
  addRow(classificationPair: any) {

    //this.dataSource.data.push(this.createNewCPVRow(classificationPair.code, classificationPair.confidence));
    this.dataSource.data.push(this.createNewCPVRow(classificationPair.code));
    this.dataSource.filter = "";
  }

  createNewForm() {
    this.procurementForm = this.fBuilder.group({
      title: [''],
      description: ['']
    })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  addRows = async function (classificationResponse) {
    if (this.dataSource.data) this.dataSource.data = []
    //await this.delay(2000)
    let sortable = []
    for (const classificationPair in classificationResponse) {
      sortable.push([classificationPair, classificationResponse[classificationPair]]);
    }
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    for (let index = 0; index < sortable.length; index++) {
      const element = sortable[index];
      const code = element[0];
      const confidence = element[1];
      this.addRow({ code: code, confidence: confidence })
      await this.delay(350)
    }
  }
    /* sortable.forEach(async(element, index) => {
      console.log('element', element)
      const code = element[0];
      const confidence = element[1];
      this.addRow({ code: code, confidence: confidence })
      await this.delay(350)
    });
  } */
  /* for (let index = 0; index < 5; index++) {
    await this.delay(350)
    
  } */

  makeRequest = function (title: string, description: string): Observable<any> {
    //return this.http.post('http://localhost:8000', { title: title, description: description })
    //https://test2-78uq00pn.ew.gateway.dev/
    return this.http.post('https://test2-78uq00pn.ew.gateway.dev/' + this.translate.currentLang, { title: title, description: description})

  }
  public saveFormData = async function (isValid: boolean, e: any) {
    if (isValid) {
      this.submitting = true
      this.procurementForm.disable()
      let title = this.procurementForm.controls.title.value
      let description = this.procurementForm.controls.description.value
      let httpSubscription = this.makeRequest(title, description).subscribe(async (result : any) => {
        console.log('result', result)
        await this.addRows(result)
        this.submitting = false
        this.procurementForm.enable()
      })
      this.subscriptions.push(httpSubscription)
    }
    /* let result = {'15800000': Number(0.234123), '15811100': 0.214123}
    console.log('result', result)
    await this.addRows(result)
        this.submitting = false
        this.procurementForm.enable()
  } */
}}

/** Constants used to fill up our datasource. */
const CODES = [
  '15800000',
  '15000000',
  '15810000',
  '15811000',
  '15811100'];
const NAMES = [
  'Miscellaneous food products',
  'Food, beverages, tobacco and related products',
  'Bread products, fresh pastry goods and cakes',
  'Bread products',
  'Bread'
];
