import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component'; // Import ContactComponent
import { ClassifierFormComponent } from './components/classifier-form/classifier-form.component'; // Import ContactComponent
import { AboutComponent } from './components/about/about.component'
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component'
export const xxxAppRoutes: Routes = [
  { path: 'home', component: ClassifierFormComponent }, // Home page route
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // Redirect to 'home' as default
];

@NgModule({
  imports: [RouterModule.forRoot(xxxAppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
