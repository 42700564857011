import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClassifierFormComponent } from './components/classifier-form/classifier-form.component';
import { TranslateService } from '@ngx-translate/core';


interface Language {
  value: string;
  viewValue: string;
}


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnDestroy, OnInit {
  private subscriptionDataError: Subscription;
  selectedLanguage: string
  languages: Language[] = [

    { value: 'en', viewValue: 'EN' },
    { value: 'sv', viewValue: 'SV' },
    { value: 'fr', viewValue: 'FR' },
  ];
  isCollapsed = true;

  constructor(
    private translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.selectedLanguage = this.translate.currentLang;
    /* const lang = this.translate.currentLang? this.translate.currentLang: localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    
    //this.setLanguage(lang);
    this.selectedLanguage = lang
    console.log(' this.selectedLanguage', this.selectedLanguage)
    console.log('localStorage', localStorage)
    console.log('translate', this.translate)
    console.log('translatecurrent;ang', this.translate.currentLang)
    console.log(' getLangs', this.translate.getLangs())
    console.log(' getTranslation', this.translate.getTranslation('sv')) */
  }

  ngOnInit(): void {
    if (this.checkCookieConsent('consentGranted')) {
      this.loadGoogleAnalytics();
    } else {
      this.showConsentBanner();
    }
  }

  ngOnDestroy() {
    this.subscriptionDataError.unsubscribe();
  }
  setLanguage(lang: string) {
    this.translate.use(lang)
    console.log('lang', lang)
    this.selectedLanguage = lang
    localStorage.setItem('language', lang)

    console.log('translatecurrent;ang', this.translate.currentLang)
  }

  changeLanguage(lang: any) {
    console.log(' e', lang)
    this.setLanguage(lang)
    //this.setLanguage(lang.target.value)
    //this.setLanguage(lang.value)
  }

  // app.component.ts
  grantConsent() {

    const adUserData = (document.getElementById('ad_user_data') as HTMLInputElement).checked ? 'granted' : 'denied';
    const adPersonalization = (document.getElementById('ad_personalization') as HTMLInputElement).checked ? 'granted' : 'denied';
    const adStorage = (document.getElementById('ad_storage') as HTMLInputElement).checked ? 'granted' : 'denied';
    const analyticsStorage = (document.getElementById('analytics_storage') as HTMLInputElement).checked ? 'granted' : 'denied';

    // Set consent as first-party cookies
    document.cookie = `consentGranted=true; path=/; SameSite=Lax; Secure`;
    document.cookie = `ad_user_data=${adUserData}; path=/; SameSite=Lax; Secure`;
    document.cookie = `ad_personalization=${adPersonalization}; path=/; SameSite=Lax; Secure`;
    document.cookie = `ad_storage=${adStorage}; path=/; SameSite=Lax; Secure`;
    document.cookie = `analytics_storage=${analyticsStorage}; path=/; SameSite=Lax; Secure`;

    // Update Google Analytics consent
    const win = window as any; // Use type assertion
    win.dataLayer = win.dataLayer || [];
    win.gtag = function () { win.dataLayer.push(arguments); }

    win.gtag('consent', 'update', {
      'ad_user_data': adUserData,
      'ad_personalization': adPersonalization,
      'ad_storage': adStorage,
      'analytics_storage': analyticsStorage
    });

    // Load the Google Analytics script
    this.loadGoogleAnalytics();

    // Hide the consent banner
    const consentBanner = document.getElementById('consent-banner');
    if (consentBanner) {
      consentBanner.style.display = 'none';
    }
  }
  private checkCookieConsent(cookieName: string): boolean {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length) === 'true';
      }
    }
    return false;
  }


  private loadGoogleAnalytics() {
    const gtagScript = this.renderer.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-KSXXHZ9YJ4';

    this.renderer.appendChild(document.head, gtagScript);

    gtagScript.onload = () => {
      const win = window as any; // Use type assertion
      win.dataLayer = win.dataLayer || [];
      win.gtag = function () { win.dataLayer.push(arguments); }
      win.gtag('js', new Date());
      win.gtag('config', 'G-KSXXHZ9YJ4');
    };
  }

  private showConsentBanner() {
    const consentBanner = document.getElementById('consent-banner');
    if (consentBanner) {
      consentBanner.style.display = 'block';
    }
  }

}
